
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  apiGameCheatsDetail,
  apiGameCheatsEdit,
  apiGameCheatsAdd, apiGameCheatsCategoryAllLists
} from '@/api/setting'
@Component({
    components: {}
})
export default class GameCheatsEdit extends Vue {
    @Prop() aid?: any
    @Prop() cid?: number
    @Prop({
        default: ''
    })
    title!: string //弹窗标题
    @Prop({
        default: '660px'
    })
    width!: string | number //弹窗的宽度
    @Prop({
        default: '20vh'
    })
    top!: string | number //弹窗的距离顶部位置
    /** S Data **/
    visible = false
    categoryList: Array<object> = []
    $refs!: {
        formRef: any
    }
    form = {
        name: '',
        package_name: '',
        category_id: '',
        is_show: 1, // 是否显示 0-不显示 1-显示
        score: 0
    }

    // 表单验证
    formRules = {
      name: [
            {
                required: true,
                message: '请输入应用名',
                trigger: 'blur'
            }
        ],
      category_id: [
        {
          required: true,
          message: '请选择分类',
          trigger: 'blur'
        }
      ],
      package_name: [
        {
          required: true,
          message: '请输入包名',
          trigger: 'blur'
        }
      ],
        is_show: [
            {
                required: true,
                message: '请选择状态',
                trigger: 'change'
            }
        ]
    }
    /** E Data **/

    // 分类详情
    getCategoryDetail() {
        apiGameCheatsDetail({
            aid: this.aid,
            id: this.cid
        })
            .then((res: any) => {
                this.form = res
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }


  changeListen() {
    for (let i = 0; i < this.categoryList.length; i++) {
      // @ts-ignore
      if (this.form.category_id != this.categoryList[i].id) continue;
      // @ts-ignore
       this.form.score = this.categoryList[i].score;
       break;
    }
  }

    // 新增分类
    onCategoryAdd() {
        apiGameCheatsAdd({
            ...this.form,
            aid: this.aid,
        })
            .then(() => {
                this.$emit('refresh')
                this.close()
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 编辑分类
    onCategoryEdit() {
        apiGameCheatsEdit({
            ...this.form,
            id: this.cid,
            aid: this.aid,
        })
            .then(() => {
                this.$emit('refresh')
                this.close()
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 提交
    onSubmit() {
        this.$refs.formRef.validate((valid: any) => {
            if (!valid) {
                return
            }
            if (this.cid) {
                this.onCategoryEdit()
            } else {
                this.onCategoryAdd()
            }
        })
    }

    // 弹窗打开触发
    onTrigger() {
        this.visible = true

        this.initCategoryLists()

        if (this.cid) {
            this.getCategoryDetail()
        }
    }

  initCategoryLists() {
    apiGameCheatsCategoryAllLists({}).then(res => {
      this.categoryList = res.lists
    })
  }

    // 关闭弹窗
    close() {
        this.visible = false

        this.form = {
            name: '',
            package_name: '',
            category_id: '',
            is_show: 1, // 是否显示 0-不显示 1-显示
            score: 0
        }
    }
    /** E Methods **/

    /** S Life Cycle **/
    /** E Life Cycle **/
}
